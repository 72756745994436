.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover{
  color: blue
}

.button{
font-family: 'Hind Guntur', sans-serif;
font-size: 15px;
line-height: 1;
color: #fff;
letter-spacing: 0.025em;
text-align: center;
background: #379aff;
padding: 7px 0 3px;
cursor: pointer;
border: 0;
border-radius: 4px;
width: 150px;
overflow: hidden;
/* display: inline-block; */
position: relative;
margin-left: 65%;
/* margin-top: 10%;
margin-left: 20%; */
/* transform: translate(-50%, -50%); */
}

.button span {
display: block;
position: relative;
z-index: 10;
}

.button:after,
.button:before {
padding: 18px 0 11px;
content: '';
position: absolute;
top: 0;
left: calc(-100% - 30px);
height: calc(100% - 29px);
width: calc(100% + 20px);
color: #fff;
border-radius: 2px;
transform: skew(-25deg);
}

.button:after {
background: #fff;
transition: left 0.8s cubic-bezier(0.86, 0, 0.07, 1) 0.2s;
z-index: 0;
opacity: 0.8;
}

.button:before {
background: #13c276;
z-index: 5;
transition: left 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.button:hover:after {
left: calc(0% - 10px);
transition: left 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.button:hover:before {
left: calc(0% - 10px);
transition: left 1s cubic-bezier(0.86, 0, 0.07, 1);
}
